import { OrderStatus } from '~/type/kmob/order-status'

export function useCancelStatusBtn() {
  const cancelStatusBtn = computed(() => {
    return (hasCancelAccess: boolean, orderStatusName: string, menu: string) => {
      const orderStatus = ref(orderStatusName)
      if (menu === 'APK') {
        if (!hasCancelAccess)
          return false
        return (
          orderStatus.value !== OrderStatus.SALLY_CANCEL
          && (orderStatus.value === OrderStatus.SALLY_NEW
            || orderStatus.value === OrderStatus.SALLY_PROCESS
            || orderStatus.value === OrderStatus.SALLY_FINISHED
            || orderStatus.value === OrderStatus.PV_PROCESS
            || orderStatus.value === OrderStatus.PV_FINISHED
            || orderStatus.value === OrderStatus.FILTERING_PROCESS
            || orderStatus.value === OrderStatus.SURVEY_UNASSIGNED
            || orderStatus.value === OrderStatus.FILTERING_FAILED)
        )
      }
      if (menu === 'AKK') {
        if (!hasCancelAccess)
          return false
        return (
          orderStatus.value !== OrderStatus.SALLY_CANCEL
          && (orderStatus.value === OrderStatus.SURVEY_NEW
            || orderStatus.value === OrderStatus.SURVEY_PROCESS
            || orderStatus.value === OrderStatus.SURVEY_FINISHED)
        )
      }
    }
  })

  return {
    cancelStatusBtn, useCancelStatusBtn,
  }
}
